/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import moment from 'moment';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import Helmet from 'react-helmet';
import { Toaster } from 'react-hot-toast';

import * as metadata from './metadata';
import { PageSpinner } from './staticComponents/spinner';
import { routeParser, paths } from './utils/helper';
import { updateUserHandler } from './service';

import {
  Hopscotch,
  TheSouledStore,
  CommonHeader,
  NetMeds,
  Herbalife,
  TrackingDemo,
  PeeSafe,
  Bewakoof,
  HealthKart,
  Libas,
  Zoomin,
  // Zivame,
  Nykaa,
  // Casper,
  NutraBay,
  Onemg,
  Usplworld,
  Ritukumar,
  Gonoise,
  Flomattress,
  Boodmo,
  Damensch,
  Sleepycat,
  Stylishop,
  Candere,
  Puma,
  Buywow,
  TheLabelLife,
  MenaMultiLang,
  JustHerbs,
  Centered,
  Trendingstall,
  Asenne,
  Thehouseofrare,
  VanheusenIndia,
  MyloFamily,
  PeterEngland,
  // AbfrlTest,
  AllenSolly,
  Pantaloons,
  LouisPhilippe,
  ViEn,
  Merchantmarine,
  NoLogoHeader,
  KalakaarIndiaa,
  BrandsForLess as BrandsForLessHeader,
  Redtag as RedtagHeader,
  HerbalChakra as HerbalChakraHeader
} from './components/headers';
import {
  CommonFooter,
  NetMeds as NetMedsFooter,
  Bewakoof as BewakoofFooter,
  Zoomin as ZoominFooter,
  Nykaa as NykaaFooter,
  Onemg as OnemgFooter,
  Usplworld as UsplworldFooter,
  Gonoise as GonoiseFooter,
  Boodmo as BoodmoFooter,
  Sleepycat as SleepycatFooter,
  Candere as CandereFooter,
  Buywow as BuywowFooter,
  Minimal as MinimalFooter,
  Thesouledstore as ThesouledstoreFooter,
  Truemeds as TruemedsFooter,
  Nutrabay as NutrabayFooter,
  Kalkifashion as KalkifashionFooter,
  Kushals as KushalsFooter,
  Thenestery as ThenesteryFooter,
  EightTwoE as EightTwoEFooter,
  BrandsForLess as BrandsForLessFooter,
  Supertails as SupertailsFooter,
  Walmart as WalmartFooter,
  OriflameFooter,
  HerbalifeFooter,
  CromaFooter,
  ScoobooFooter,
  BaccabucciFooter
} from './components/footers';
import Authenticate from './sharedComponents/requiresAuth';
import { toastStyle, langOption } from './utils/entity_mapping';
import SurveyLink from './components/surveyLink';

const noHeaders = ['shop101test', 'retail'];

const getTrackingPageComponent = subdomain => {
  switch (subdomain) {
    // case 'tracking-demo':
    case 'nykaa':
    case 'nykaa-test':
    case 'nykaa-b2b':
    case 'nykaab2btest':
    case 'marico':
      return import(
        /* webpackChunkName: 'tracking' */ './components/themes/slick/tracking'
      );
    case 'shop101test':
    case 'retail':
    case 'trackmyorder':
    case 'hp':
    case '1mg':
    case '1mg-test':
    case 'walmart-ca':
    case 'walmart-mx':
    case 'damensch':
   
      return import(
        /* webpackChunkName: 'tracking' */ './components/themes/compact/tracking'
      );
    case '':
      return import(
        /* webpackChunkName: 'tracking' */ './components/themes/common/tracking'
      );
    default:
      return import(/* webpackChunkName: 'tracking' */ './components/tracking');
  }
};

const LoadableHome = subdomain =>
  Loadable({
    loader: () => import(/* webpackChunkName: 'home' */ './components/home'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });
const LoadablePincode = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'home' */ './components/pincodeServiceability'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableMyOrder = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'myOrder' */ './components/myOrder'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableTracking = (subdomain, location) =>
  Loadable({
    loader: () => getTrackingPageComponent(subdomain, location),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableIssue = subdomain =>
  Loadable({
    loader: () => import(/* webpackChunkName: 'issue' */ './components/issue'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableReverseIssue = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'reverseIssue' */ './components/reverse/custom/issue'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableUpdateContact = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'updateContact' */ './components/updateContact'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableReturnLayout = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'return' */ './components/return/index'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableExchangeLayout = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'return' */ './components/exchange/newIndex'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableOrder = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'order' */ './components/return/order'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });
const LoadableNewOrder = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'order' */ './components/return/newOrder'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableExchangeOrder = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'order' */ './components/exchange/order'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableExchangeStatus = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'order' */ './components/exchange/status'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableSelfShipOrder = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'selfShipOrder' */ './components/return/selfShipOrder'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableRequestStatus = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'requestStatus' */ './components/return/requestStatus'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableNotFound = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'NotFound' */ './components/NotFound'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableLogin = subdomain =>
  Loadable({
    loader: () => import(/* webpackChunkName: 'Login' */ './components/login'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableReverse = subdomain =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'Reverse' */ './components/reverse/custom/index'
      ),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const getHeaderComponent = user => {
  switch (user.subdomain) {
    case 'hopscotch':
    case 'hopscotch-test':
      return <Hopscotch />;
    case 'thesouledstore':
      return <TheSouledStore />;
    case 'netmeds':
      return <NetMeds />;
    case 'herbalife':
    case 'herbalife-test':
    case 'herbalife-id':
    case 'herbalife-fr':
      // case 'herbalife-apac':
      return <Herbalife />;
    case 'herbalife-apac':
      return <ViEn />;
    case 'tracking-demo':
      return <TrackingDemo />;
    // return <Zivame />;
    case 'peesafe':
      return <PeeSafe />;
    case 'bewakoof':
      return <Bewakoof />;
    case 'healthkart':
      return <HealthKart />;
    case 'libas':
      return <Libas />;
    case 'zoomin':
      return <Zoomin />;
    case 'nykaa':
    case 'nykaa-test':
    case 'nykaa-b2b':
    case 'nykaakaybeauty':
    case 'nykaab2btest':
    case 'nykaanyveda':
    case 'twenty-dresses-test':
      return <Nykaa />;
    case 'nutrabay':
    case 'nutrabay-test':
      return <NutraBay />;
    case '1mg':
    case '1mg-test':
      return <Onemg />;
    case 'usplworld':
    case 'wrogn':
      return <Usplworld />;
    case 'ritukumar':
    case 'labelritukumar':
      return <Ritukumar />;
    case 'gonoise':
      return <Gonoise />;
    case 'flomattress':
      return <Flomattress />;
    case 'shop101test':
    case 'retail':
    case 'trackmyorder':
      return null;
    case 'boodmo':
      return <Boodmo />;
    case 'damensch':
      return <Damensch user={user} />;
    case 'sleepycat':
      return <Sleepycat />;
    case 'stylishop':
    case 'stylifashion-demo':
    case 'stylishop-test':
      return <Stylishop />;
    case 'candere':
    case 'candere-test':
      return <Candere />;
    case 'puma':
    case 'puma-test':
      return <Puma />;
    case 'buywow':
    case 'bodycupid':
    case 'papabrands':
      return <Buywow />;
    case 'thelabellife':
      return <TheLabelLife />;
    case 'eyewa':
    case 'fashionsa':
    case 'theluxurycloset':
    case '6thstreet':
    case '6thstreet-test':
      return <MenaMultiLang />;
    case 'justherbs':
      return <JustHerbs />;
    case 'herbalchakra':
      return <HerbalChakraHeader />;
    case 'veromoda':
    case 'only':
    case 'jackjones':
    case 'selectedhomme':
    case 'galwaykart':
    case 'fabricpandit':
    case 'theindusvalley':
    case 'theindusvalley-test':
    case 'iconicindia':
    case 'khimji':
    case 'boddess':
    case 'thebodyshop':
    case 'kiehls':
    case 'thefaceshop':
    case 'rituals':
    case 'thecollective':
    case 'abfrl-test':
    case 'simoncarter':
    case 'vastrado':
    case 'chemistatplay':
    case 'bareanatomy':
    case 'xyxx':
    case 'hummel':
    case 'thewhitewillow':
    case 'whitewillow-test':
    case 'magicleafstevia':
    case 'ragecoffee':
    case 'dhani-test':
    case 'charactercosmetics':
    case 'jpearls':
    case 'suta':
    case 'gritstones':
    case 'dhanimp':
    case 'dhani':
    case 'baccabucci':
    case 'frenchcrown':
    case 'zouk':
    case 'theayurvedaco':
    case 'theater':
    case 'rforrabbit':
    case 'louisstitch':
    case 'jolene':
    case 'thecosmicbyte':
    case 'nilkamalfurniture':
    case 'louisstitchre':
    case 'snitch':
    case 'danielklein':
    case 'forestessentialsindia':
    case 'relaxcompany':
    case 'manyavar':
    case 'dopeshope':
    case 'twentydresses':
    case 'athlab':
    case 'forestessentials':
    case 'invogue':
    case 'birdsofparadyes':
    case 'teejh':
    case 'letsbeco':
    case 'skewdeckindia':
    case 'savana':
    case 'minibay':
    case 'urturms':
    case 'three60you':
    case 'crocodile':
    case 'kicaactive':
    case 'mymuse':
    case 'chemistryindia':
    case 'maharishiayurvedaindia':
    case 'tintcosmetics':
    case 'salt-lifestyle':
    case 'nutriorg':
    case 'embercookware':
    case 'blissclub':
    case 'skippi':
    case "zeespares":
      return <Centered />;
    case 'brandsforless':
    case 'brandsforless-test':
      return <BrandsForLessHeader />;
    case 'trendingstall':
      return <Trendingstall />;
    case 'asenne':
      return <Asenne />;
    case 'thehouseofrare':
      return <Thehouseofrare />;
    case 'vanheusenindia':
    case 'abfrl-vhi':
    case 'vhi-test':
      return <VanheusenIndia />;
    case 'pantaloons':
    case 'abfrl-test2':
      return <Pantaloons />;
    case 'mylofamily':
      return <MyloFamily />;
    // case 'abfrl-test':
    //   return <AbfrlTest />;
    case 'louisphilippe':
    case 'louisphilippe-test':
      return <LouisPhilippe />;
    case 'peterengland':
      return <PeterEngland />;
    case 'allensolly':
    case 'allensolly-test':
      return <AllenSolly />;
    case 'merchantmarine':
      return <Merchantmarine />;
    case 'cbazaar':
      return <NoLogoHeader />;
    case 'kalakaarindiaa':
      return <KalakaarIndiaa />;
    case 'redtagfashion':
    case 'redtag-test':
      return <RedtagHeader />;
    case 'jv':
      return null;
    default:
      return <CommonHeader />;
  }
};

const getFooterComponent = user => {
  switch (user) {
    case 'netmeds':
      return <NetMedsFooter />;
    case 'bewakoof':
      return <BewakoofFooter />;
    case 'zoomin':
    case 'pharmeasy':
    case 'pharmeasy-otc':
    case 'pharmeasy-test':
    case 'decathlon-staging':
      return <ZoominFooter />;
    case 'nykaa':
    case 'nykaa-test':
    case 'nykaa-b2b':
    case 'nykaakaybeauty':
    case 'nykaab2btest':
    case 'nykaanyveda':
    case 'twenty-dresses-test':
      return <NykaaFooter />;
    case '1mg':
    case '1mg-test':
      return <OnemgFooter />;
    case 'usplworld':
    case 'wrogn':
      return <UsplworldFooter />;
    case 'thesouledstore':
      return <ThesouledstoreFooter />;
    case 'truemeds':
    case 'truemeds-test':
      return <TruemedsFooter />;
    case 'kushals':
      return <KushalsFooter />;
    case 'thenestery':
      return <ThenesteryFooter />;
    case 'herbalife':
    case 'toolsvilla':
    case 'flomattress':
    case 'lenovo':
    case 'jockey':
    case 'buy91':
    case 'marico-dbu':
    case 'koskii':
    case 'koskii-test':
    case 'smartivity':
    case 'headsupfortails':
    case 'headsupfortails-test':
    case 'truebrowns':
    case 'timex':
    case 'limese':
    case 'littletags':
    case 'abhishti':
    case 'agrim-test':
    case 'agrim':
    case 'curlvana':
    case 'voylla':
    case 'ethnicroop':
    case 'gehnaindia':
    case 'breakbounce':
    case 'neemlinaturals':
    case 'onbeli':
    case 'beautybarn':
    case 'porter-test':
    case 'bostreet':
    case 'porter':
    case 'sugarpop':
    case 'quenchbotanics':
    case 'articale':
    case 'almowear':
    case 'birkenstock':
    case 'birkenstock-test':
    case 'rforrabbit':
    case 'fashinza':
    case 'attitudist':
    case 'kapiva':
    case 'pratechbrands':
    case 'discoverpilgrim':
    case 'solvezy':
    case 'bookscape':
    case 'jaipurrugs':
    case 'dhanimp':
    case 'ugaoo':
    case '6thstreet-test':
    case 'cureskin':
    case 'hudson-malta':
    case 'theyogamanlab':
    case 'bombayshirts':
    case 'thehomeoffice':
    case 'jhajistore':
    case 'symphonylimited':
    case 'offineeds':
    case 'acer':
    case 'headsupfortails-returns':
    case 'ahika':
    case 'happilo':
    case 'nykaainternational':
    case 'mablejewelry':
    case 'reebok':
    case 'clickpost-returns':
    case 'utkarshbank':
    case 'divinetrendz':
    case 'thesleepcompany':
    case 'hamiltoncollection':
    case 'swisse':
    case 'centralbooks':
    case 'mojocare':
    case 'pintola':
    case 'growsimplee':
    case 'chumbak':
    case 'dhani':
    case 'letsdressup':
    case 'donetella':
    case 'numerounojeanswear':
    case 'thewholetruthfoods':
    case 'transcendstore':
    case 'schwingstetterindia':
    case '21kschool':
    case 'benq':
    case 'naturederma':
    case 'jpearls':
    case 'suta':
    case 'knyamed':
    case 'imara':
    case 'powerlook':
    case 'cosmix':
    case 'bajaao':
    case 'azafashions':
    case 'beatxp':
    case 'beyours':
    case 'organicnetra':
    case 'sunohstudio':
    case 'theayurvedaco':
    case 'estilocus':
    case 'bluestone':
    case 'bluestoneb2b':
    case 'wellversed':
    case 'badmaash':
    case 'sothebys':
    case 'goscul':
    case 'sassafras':
    case 'gritstones':
    case 'befikray':
    case 'frenchcrown':
    case 'zouk':
    case 'portronics':
    case 'salty':
    case 'grtjewels':
    case 'theorganicbite':
    case 'pepejeans':
    case 'pepejeansb2b':
    case 'westside-test':
    case 'westside':
    case 'theater':
    case 'damenschb2b':
    case 'damensch':
    case 'thebearhouse':
    case 'totemgoods':
    case 'unbottle':
    case 'rhinoshield':
    case 'popsockets':
    case 'swissbeauty':
    case 'ravelcare':
    case 'fitflop':
    case 'evoljewels':
    case 'louisstitch':
    case 'optimumnutrition':
    case 'carltonlondon':
    case 'americantourister':
    case 'sweetkaramcoffee':
    case 'araviorganic':
    case 'leemboodi':
    case 'dusaan':
    case 'italiancolony':
    case 'maersk':
    case 'yflhome':
    case 'reliancefreightsystems':
    case 'quickshift':
    case 'zivia':
    case 'thecosmicbyte':
    case 'indigenoushoney':
    case 'drvaidyas':
    case 'shypbuddy':
    case 'louisstitchre':
    case 'redingtononline':
    case 'wiremart':
    case 'jaypore-test':
    case 'jaypore-new':
    case 'itsaugust-test':
    case 'aweri':
    case 'danielklein':
    case 'devgange':
    case 'forestessentialsindia':
    case 'spiderhq':
    case 'relaxcompany':
    case 'vguard':
    case 'tigc':
    case 'dirtsstore':
    case 'ajnaajewels':
    case 'zeroharm':
    case 'jockey-test':
    case 'mokobara':
    case 'mokobara-test':
    case 'killerjeans':
    case 'minaturewellness':
    case 'emmasleep':
    case 'honortech':
    case '10clubhomes':
    case 'manyavar':
    case 'mrhome':
    case 'memorazo':
    case 'dopeshope':
    case 'beyondpinkshop':
    case 'twentydresses':
    case 'contlo':
    case 'thegoodbug':
    case 'snooplay':
    case 'speedo':
    case 'happenstance':
    case 'celloworld':
    case 'decodeage':
    case 'athlab':
    case 'jsbhealthcare':
    case 'heromotocorp':
    case 'pinkfort':
    case 'abkimports':
    case 'fiftymade':
    case 'solaredge':
    case 'twobrothersindiashop':
    case 'bonkerscorner':
    case 'vaaree':
    case 'mywakeup':
    case 'srimandir':
    case 'signzy':
    case 'reequil':
    case 'bartisans':
    case 'shop-recodestudios':
    case 'speedo-test':
    case 'houseofaldeno':
    case 'caratlane':
    case 'caratlane-test':
    case 'forestessentials':
    case 'sudathi':
    case 'berryliciousclothing':
    case 'invogue':
    case 'symphonyd2c':
    case 'birdsofparadyes':
    case 'girorganic':
    case 'dreamyatoms':
    case 'svastika':
    case 'babiesandmore':
    case 'femmella':
    case 'quirksmith':
    case 'pcjeweller':
    case 'jokerandwitch':
    case 'nutriherbs':
    case 'teejh':
    case 'amrithnoni':
    case 'controlz':
    case 'avimeeherbal':
    case 'hotheadscap':
    case 'mydesignation':
    case 'zeespares':
    case 'skewdeckindia':
    case 'justintime':
    case 'itc':
    case 'diamondxe':
    case 'piramal':
    case 'onlinebandhej':
    case 'eridani':
    case 'blueisland':
    case 'minibay':
    case 'urturms':
    case 'kisah':
    case 'infinixmobiles':
    case 'three60you':
    case 'oldmarigold':
    case 'craftise':
    case 'cellbell':
    case 'ramanbag':
    case 'sumuglobal':
    case 'huskify':
    case 'sunglassic':
    case 'myfrido':
    case 'crocodile':
    case 'banlabs':
    case 'magicleafstore':
    case 'mygossip':
    case 'spicebasket':
    case 'setu':
    case 'assemblytravel':
    case 'dennison':
    case 'ozonesignature':
    case 'label-s4u':
    case 'kontacts':
    case 'ludic':
    case 'sunriseclick':
    case 'columbiasportswear':
    case 'clarks':
    case 'innovist':
    case 'gladful':
    case 'justherbs':
    case 'seelingo':
    case 'angelandrocket':
    case 'harfun':
    case 'palmonas':
    case 'barcodeprofessional':
    case 'rblbank':
    case 'rblbank-test':
    case 'freakins':
    case 'bighaat':
    case 'titanb2b':
    case 'arata':
    case 'virgio':
    case 'myborosil':
    case 'getketch':
    case 'filohevis':
    case 'mymuse':
    case 'tasva':
    case 'tasva-test':
    case 'underfourteenonly':
    case 'maharishiayurvedaindia':
    case 'yardleyoflondon':
    case 'fiama':
    case 'engageshop':
    case 'dermafique':
    case 'potandbloom':
    case 'glenmarkpharma':
    case 'purehomeandliving':
    case 'thedailynutco':
    case 'chemistryindia':
    case 'grandpitstop':
    case 'herbalchakra':
    case 'bombaytrooper':
    case 'salt-lifestyle':
    case 'nutriorg':
    case 'kayradecor':
    case 'zapcart':
    case 'dr-rashel':
    case 'sahyogwellness':
    case 'sunrisesports-test':
    case 'colorcupid':
    case 'plaeto':
    case 'runners':
    case 'berrylush':
    case 'footlocker':
    case 'sprig-store':
    case 'meeshob2b-test':
    case 'meeshob2b':
    case 'napchief':
    case 'tmrw':
    case 'cult-fit':
    case 'pilolo':
    case 'bugattishoes':
    case 'bagatt':
    case 'nubeat':
    case 'titanwatches':
    case 'blissclub':
    case 'skippi':
      return <CommonFooter />;
    case 'brandsforless-test':
    case 'brandsforless':
      return <BrandsForLessFooter />;
    case 'gonoise':
      return <GonoiseFooter />;
    case 'supertails':
      return <SupertailsFooter />;
    case 'shop101test':
    case 'retail':
    case 'stylishop':
    case 'stylifashion-demo':
    case 'stylishop-test':
    case 'simsim':
    case 'simsim-test':
      return null;
    case 'boodmo':
      return <BoodmoFooter />;
    case 'sleepycat':
      return <SleepycatFooter />;
    case 'candere':
    case 'candere-test':
      return <CandereFooter />;
    case 'buywow':
    case 'bodycupid':
      return <BuywowFooter />;
    case '82e':
      return <EightTwoEFooter />;
    case 'herbalife-test':
    case 'herbalife-id':
    case 'herbalife-fr':
      return <HerbalifeFooter />;
    case 'croma':
      return <CromaFooter />;
    case 'scooboo':
      return <ScoobooFooter />;
    case 'baccabucci':
      return <BaccabucciFooter />;
    case 'superbottoms':
    case 'hindveda':
    case 'thelabellife':
    case 'plumgoodness':
    case 'pureplay-test':
    case 'fashor':
    case 'thephylife':
    case 'eyewa':
    case 'fashionsa':
    case 'arvind-test':
    case 'adidas':
    case 'solethreads':
    case 'thesirona':
    case 'pantaloons':
    case 'louisphilippe':
    case 'nice':
    case 'iloveunloc':
    case 'lifepharmacy':
    case 'shopdivine':
    case 'theluxurycloset':
    case 'teabox':
    case 'tatacoffee':
    case 'sleepyowl':
    case 'aldoshoes':
    case 'vfullfill-test':
    case '6thstreet':
    case 'bestseller':
    case 'trell':
    case 'manmatters':
    case 'veromoda':
    case 'bestsellerclothing':
    case 'only':
    case 'jackjones':
    case 'selectedhomme':
    case 'galwaykart':
    case 'fabricpandit':
    case 'theindusvalley':
    case 'theindusvalley-test':
    case 'titaneyeplus':
    case 'headphonezone':
    case 'gerua':
    case 'workstore':
    case 'trendingstall':
    case 'jv':
    case 'mamaearth':
    case 'iconicindia':
    case 'nutrova':
    case 'khimji':
    case 'thehouseofrare':
    case 'boddess':
    case 'thebodyshop':
    case 'kiehls':
    case 'belifindia':
    case 'purplle':
    case 'thefaceshop':
    case 'boltt':
    case 'randbfashion':
    case 'rituals':
    case 'vanheusenindia':
    case 'mylofamily':
    case 'peterengland':
    case 'allensolly':
    case 'abfrl-test':
    case 'abfrl-test2':
    case 'hafeleindia':
    case 'at-home':
    case 'nilkamalsleep':
    case 'femella':
    case 'nilkamalfurniture':
    case 'vastrado':
    case 'anscommerce':
    case 'mars':
    case 'toniq':
    case 'simoncarter':
    case 'forever21':
    case 'thecollective':
    case 'nutristar':
    case 'vanheusenintimates':
    case 'aeo':
    case 'planetfashion':
    case 'priyaasi':
    case 'parasspecialityclinic':
    case 'livpuresleep':
    case 'hcah':
    case 'hcah-test':
    case 'hopscotch':
    case 'hopscotch-test':
    case 'herbalife-apac':
    case 'papabrands':
    case 'zinklondon':
    case 'themancompany':
    case 'ritukumar':
    case 'labelritukumar':
    case 'industrybuying':
    case 'shantanunikhil':
    case 'vfulfill':
    case 'printstop':
    case 'lining':
    case 'asus':
    case 'realme':
    case 'shopthearena':
    case 'sharepal':
    case 'merchantmarine':
    case 'chemistatplay':
    case 'venusremedies':
    case 'bareanatomy':
    case 'puma':
    case 'puma-test':
    case 'xyxx':
    case 'hummel':
    case 'propshop24':
    case 'lenskart':
    case 'thewhitewillow':
    case 'whitewillow-test':
    case 'trueelements':
    case 'asteronline':
    case 'giordano':
    case 'globalbees':
    case 'gynoveda':
    case 'hudson':
    case 'hudson-test':
    case 'nessa':
    case 'philips':
    case 'bira91':
    case 'suprfit':
    case 'gourmetgarden':
    case 'globalbees-test':
    case 'neemans':
    case 'benetton':
    case 'benetton-test':
    case 'firefoxbikes':
    case 'magicleafstevia':
    case 'lazypay':
    case 'retailio':
    case 'asenne':
    case 'youlry':
    case 'meadbery':
    case 'printo-test':
    case 'printo':
    case 'anveya':
    case 'thriveco':
    case 'cbazaar':
    case 'miraggiolife':
    case 'redesyn':
    case 'indianterrain':
    case 'sugarcosmetics':
    case 'unicommerce-test':
    case 'melorra':
    case 'devfolio':
    case 'beminimalist':
    case 'dentalkart':
    case 'v2kart':
    case 'herolectro':
    case 'ragecoffee':
    case 'urbankisaan':
    case 'goodearth':
    case 'themomstore':
    case 'slursh':
    case 'dailylifeforever52':
    case 'marico':
    case 'rareism':
    case 'mtrack':
    case 'pered':
    case 'zavya':
    case 'suwasthi':
    case 'jaipurkurti':
    case 'lakshitaonline':
    case 'ourlittlejoys':
    case 'sskl':
    case 'rekkoz':
    case 'miniklub':
    case 'mysleepyhead':
    case 'aubank':
    case 'kalakaarindiaa':
    case 'menmoms':
    case 'grow91':
    case 'openmoney':
    case 'abof':
    case 'apnaklub':
    case 'rustorange':
    case 'ethnicity':
    case 'prozo':
    case 'trayahealth':
    case 'hisher':
    case 'hishers':
    case 'glootstore':
    case 'nykdbynykaa':
    case 'kicaactive':
    case 'foxtale':
    case 'doggiedabbas':
    case 'konnectbox':
    case 'frangipani':
    case 'koshiqa':
    case 'dslr':
    case 'ennbeauty':
    case 'marscosmetics':
    case 'pepeinner':
    case 'upscalio':
    case 'thegivingmovement':
    case 'tgm-test':
    case 'allensolly-test':
    case 'louisphilippe-test':
    case 'vhi-test':
    case 'plixlife':
    case 'clickpost-demo':
    case 'themanch':
    case 'dhani-test':
    case 'popclub':
    case 'charactercosmetics':
    case 'clickpost-returns-4':
    case 'trackmyorder':
    case 'xnara':
    case 'ketofy':
    case 'teamonk':
    case 'shantanunikhil-test':
    case 'redtagfashion':
    case 'littleboxindia':
    case 'urbanic':
    case 'clickpost-returns-8':
    case 'pepperfry':
    case 'pepperfry-test':
    case 'jolene':
    case 'redtag-test':
    case 'atlys':
    case 'babylama':
    case 'miglowstore':
    case 'wearyourwords':
    case 'cookdtv':
    case 'decathlon-b2b':
    case 'famyo':
    case 'snitch':
    case 'returnsdemo':
    case 'letsbeco':
    case 'okerliving':
    case 'swybrands-test':
    case 'alamodelabel':
    case 'savana':
    case 'motorola':
    case 'motorola-test':
    case 'newme':
    case 'titancompany':
    case 'pitshirts':
    case 'smartcommerce-amazon':
    case 'tintcosmetics':
    case 'otrix':
    case 'nashermiles':
    case 'aubank-test':
    case 'embercookware':
      return <MinimalFooter />;
    case 'nutrabay':
    case 'nutrabay-test':
      return <NutrabayFooter />;
    case 'kalkifashion':
      return <KalkifashionFooter />;
    case 'walmart-ca':
    case 'walmart-mx':
      return <WalmartFooter />;
    case 'oriflame-test':
    case 'oriflame':
      return <OriflameFooter />;
    default:
      return <ZoominFooter />;
  }
};

const loadReturnPage = (subdomain, isReturnOrderUrl, requiredLogin) => {
  if (isReturnOrderUrl) {
    if (['trayahealth'].includes(subdomain)) {
      return LoadableNewOrder(subdomain);
    }
    if (subdomain === 'thesouledstore1') {
      return LoadableSelfShipOrder(subdomain);
    } else {
      return LoadableOrder(subdomain);
    }
  } else if (requiredLogin || requiredLogin === undefined) {
    return Authenticate(LoadableReturnLayout(subdomain));
  } else {
    return LoadableReturnLayout(subdomain);
  }
};

const loadExchangePage = (subdomain, isReturnOrderUrl, requiredLogin) => {
  if (isReturnOrderUrl) {
    return LoadableExchangeOrder(subdomain);
  } else if (requiredLogin || requiredLogin === undefined) {
    return Authenticate(LoadableExchangeLayout(subdomain));
  } else {
    return LoadableExchangeLayout(subdomain);
  }
};

const toggleNav = (e, user, dispatch) => {
  user = Object.assign(user, { toggleNav: !user.toggleNav });
  dispatch(updateUserHandler(user));
};

const App = ({ location, user, dispatch, requiredLogin, page, theme }) => {
  const abfrlSubdomains = [
    'shantanunikhil',
    'shantanunikhil-test',
    'reebok',
    'louisphilippe-test',
    'louisphilippe',
    'allensolly',
    'allensolly-test',
    'vanheusenindia',
    'vhi-test',
    'abfrl-test',
    'peterengland',
    'abfrl-test2',
    'pantaloons',
    'vanheusenintimates',
    'thecollective',
    'pered',
    'aeo',
    'forever21',
    'planetfashion',
    'abof',
    'simoncarter',
    'pantaloons'
  ];

  const { pathname } = location;
  const routeInfo = routeParser(location);
  const lang = theme.meta_data?.languages;

  useEffect(() => {
    if (user?.lang === 'fr') {
      moment.locale('fr', {
        months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
          '_'
        ),
        monthsShort: 'Janv._Fév._Mar_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split(
          '_'
        ),
        monthsParseExact: true
      });
    }
    if (lang?.enabled && lang?.enabled !== 'false' && lang?.default !== 'en') {
      // Check if any language is present in the pathname
      const languageExists = Object.keys(langOption).some(lang =>
        location?.pathname?.includes(`/${lang}`)
      );
      //splitting the path for adding language code in the url e.g.: ['', 'return']
      const pathParts = location.pathname.split('/');
      //adding language code in the url e.g.: ['en', 'return']
      pathParts[0] = lang.default;
      const params = new URLSearchParams(location.search);
      if (!languageExists && window?.location?.href) {
        window.location.href = `/${pathParts.join('/')}${
          params?.size > 0 ? `?${params.toString()}` : ''
        }`;
      }
    }
  }, []);
  const showHeader =
    [
      '/login',
      '/en/login',
      '/ar/login',
      '/vi/login',
      '/hi/login',
      '/mr/login',
      '/bn/login',
      '/gu/login',
      '/te/login',
      '/ta/login',
      '/id/login',
      '/kn/login'
    ].indexOf(pathname) < 0 &&
    !page.appOnly &&
    noHeaders.indexOf(user.subdomain) < 0;
  return (
    <div
      className={`${user.toggleNav ? 'open-nav' : ''}`}
      onClick={user.toggleNav ? e => toggleNav(e, user, dispatch) : null}
    >
      <div
        className={`App ${
          user.toggleNav && user.subdomain === 'netmeds'
            ? 'netmeds-slide-right'
            : ''
        }`}
      >
        <Helmet
          title={metadata.title}
          meta={metadata.meta}
          link={metadata.link}
          script={metadata.script}
          noscript={metadata.noscript}
        />
        {theme.config &&
        theme.config.forward_page_message_enabled &&
        theme.config.forward_page_message &&
        paths.forward.indexOf(location.pathname) > -1 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: theme.config.forward_page_message
            }}
            id={'forward-page-message'}
          ></div>
        ) : null}
        {theme.config &&
        theme.config.return_page_message_enabled &&
        theme.config.return_page_message &&
        paths.return.indexOf(location.pathname) > -1 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: theme.config.return_page_message
            }}
            id={'return-page-message'}
          ></div>
        ) : null}
        {abfrlSubdomains.includes(user.subdomain) &&
          paths.return.indexOf(location.pathname) > -1 && <SurveyLink />}
        {showHeader ? getHeaderComponent(user) : null}
        <Switch>
          <Route
            exact
            path="/my-order"
            component={LoadableMyOrder(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/my-order"
            component={LoadableMyOrder(user.subdomain)}
          />
          <Route
            exact
            path="/pincode"
            component={
              user.subdomain === 'herbalife'
                ? LoadablePincode(user.subdomain)
                : LoadableNotFound(user.subdomain)
            }
          />
          <Route
            exact
            path="/:lang/pincode"
            component={
              user.subdomain === 'herbalife'
                ? LoadablePincode(user.subdomain)
                : LoadableNotFound(user.subdomain)
            }
          />
          <Route
            exact
            path="/issue"
            component={LoadableIssue(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/reverse/issue"
            component={LoadableReverseIssue(user.subdomain)}
          />
          <Route
            exact
            path="/reverse/issue"
            component={LoadableReverseIssue(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/update-contact"
            component={LoadableUpdateContact(user.subdomain)}
          />
          <Route
            exact
            path="/update-contact"
            component={LoadableUpdateContact(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/login"
            component={LoadableLogin(user.subdomain)}
          />
          <Route
            exact
            path="/login"
            component={LoadableLogin(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/returns"
            component={loadReturnPage(
              user.subdomain,
              routeInfo && routeInfo.isReturnOrderUrl,
              requiredLogin
            )}
          />
          <Route
            exact
            path="/returns"
            component={loadReturnPage(
              user.subdomain,
              routeInfo && routeInfo.isReturnOrderUrl,
              requiredLogin
            )}
          />
          <Route
            exact
            path="/:lang/return-exchange"
            component={loadExchangePage(
              user.subdomain,
              routeInfo && routeInfo.isReturnOrderUrl,
              requiredLogin
            )}
          />
          <Route
            exact
            path="/return-exchange"
            component={loadExchangePage(
              user.subdomain,
              routeInfo && routeInfo.isReturnOrderUrl,
              requiredLogin
            )}
          />

          <Route
            exact
            path="/return-exchange/status"
            component={LoadableExchangeStatus(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/return-exchange/status"
            component={LoadableExchangeStatus(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/returns/status"
            component={LoadableRequestStatus(user.subdomain)}
          />
          <Route
            exact
            path="/returns/status"
            component={LoadableRequestStatus(user.subdomain)}
          />
          <Route
            exact
            path="/:lang/reverse"
            component={LoadableReverse(user.subdomain)}
          />
          <Route
            exact
            path="/reverse"
            component={LoadableReverse(user.subdomain)}
          />
          <Route
            exact
            path="/page"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/:lang/page"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/:lang/issue"
            component={LoadableIssue(user.subdomain)}
          />
          <Route
            exact
            path="/tracking"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/:lang/tracking"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/en"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/ar"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/vi"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/hi"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/mr"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/bn"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/gu"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/te"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/ta"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/id"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/kn"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/fr"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route
            exact
            path="/"
            component={
              routeInfo && routeInfo.isTrackingUrl
                ? LoadableTracking(user.subdomain, location)
                : LoadableHome(user.subdomain)
            }
          />
          <Route component={LoadableNotFound(user.subdomain)} />
        </Switch>
        {showHeader &&
        !(routeInfo.isReturnOrderUrl && user.subdomain === 'vaaree')
          ? getFooterComponent(user.subdomain)
          : null}
      </div>
      <Toaster
        toastOptions={{
          duration: 5000,
          className: 'custom-toast',
          style: {
            background: 'var(--base-toast-background-color)',
            color: 'var(--base-toast-color)'
          },
          error: {
            ...toastStyle.error
          }
        }}
      />
    </div>
  );
};
export default withRouter(App);
