import React from 'react';
import { LETSDRESSUP } from '../defaultValues';

export const variantListSetter = (list, currency, subdomain) => {
  if (subdomain === LETSDRESSUP) {
    return list.map(item => ({
      label: (
        <div className="justify-between xs-flex d-flex">
          <span>{item.title} </span>{' '}
          <span className="fw-700 ml-auto">
            {currency} {item.price}
          </span>
        </div>
      ),
      item,
      value: item.variant_id
    }));
  }

  return (
    list
      // .filter(item => item.sellableonlinequantity > 0)
      .map(item => ({
        label: (
          <div className="justify-between xs-flex d-flex">
            <span>
              {item.title}{' '}
              {item.sellableonlinequantity <= 0 ? (
                <>
                  {' '}
                  - <span className="text-red">Out of stock</span>{' '}
                </>
              ) : null}
            </span>{' '}
            <span className="fw-700 ml-auto">
              {currency} {item.price}
            </span>
          </div>
        ),
        item,
        value: item.variant_id,
        isDisabled: item.sellableonlinequantity <= 0
      }))
  );
};

export const getCatalogueExchangeTotal = (orderInfo, exchangeConfig) => {
  const {
    catalogueConfig: { percentageDiscount },
    forward_order_discount
  } = exchangeConfig;
  const productList = Object.values(orderInfo.request) ?? [];
  const { totalPriceDiff, ...rest } = productList.reduce(
    (acc, curr) => {
      if (curr.type === 'exchange') {
        let calculatedPriceDiff = curr.priceDiff ?? 0;

        if (forward_order_discount) {
          calculatedPriceDiff = curr.priceDiffWithoutDiscount ?? 0;
        } else if (percentageDiscount) {
          calculatedPriceDiff = curr.priceDiffWithDiscount ?? 0;
        }

        acc.totalPriceDiff += (calculatedPriceDiff ?? 0) * curr.quantity;
        acc.totalDiscountUsed +=
          (curr.price_breakup?.discount ?? 0) * curr.quantity;
        acc.totalShippingPaid +=
          (exchangeConfig.catalogueConfig.refund_shipping_fees_for_order
            ? curr.price_breakup?.shipping_amount ?? 0
            : 0) * curr.quantity;
      }
      return acc;
    },
    {
      totalPriceDiff: 0,
      totalDiscountUsed: 0,
      totalShippingPaid: 0
    }
  );
  const totalDiffSign = Math.sign(
    totalPriceDiff +
      (exchangeConfig?.catalogueConfig?.exchange_request_fees ?? 0) +
      (exchangeConfig?.catalogueConfig?.exchange_shipping_fees ?? 0) -
      rest.totalShippingPaid
    // (percentageDiscount || forward_order_discount
    //   ? 0
    //   : rest.totalDiscountUsed)
  );

  return { totalDiffSign, totalPriceDiff, ...rest };
};

export const getBooleanfromString = value => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return !!value;
  }
};